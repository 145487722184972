import "./ShortModalForm.css";

import React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setIsClose } from "../../redux/ShortCallbackFormSlice/ShortCallbackFormSlice";
import ReactInputMask from "react-input-mask";
import { useTelegramMessage } from "../../utils/request";
import shortFormCloseIcon from '../../assets/icons/close__icon.webp'

const ShortModalForm = () => {
  const dispatch = useDispatch();
  const isActive = useSelector((state) => state.shortModalForm.isActive);

  const [userPhone, setUserPhone] = useState("");
  const [PhoneError, setPhoneError] = useState(false);

  const { sendTelegramMessage } = useTelegramMessage();

  const handleCloseForm = () => {
    dispatch(setIsClose());
  };

  const handlePhoneNumberChange = (e) => {
    setUserPhone(e.target.value);
    setPhoneError(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let phoneErrorFlag = false;

    if (userPhone && !userPhone.includes("_")) {
      setPhoneError(false);
    } else {
      setPhoneError(true);
      e.preventDefault();
      phoneErrorFlag = true;
    }

    if (!phoneErrorFlag) {
      const message = `\u{1F408}КОЖАНЫЙ!!!\u{1F408}\nТОКО НОМЕР, имя узнавайте сами\n Номер телефона: ${userPhone}\n`;
      await sendTelegramMessage(message);
      setUserPhone("");
    }
  };

  return (
    <div className={`short__modal ${isActive ? "active" : ""}`}>
      <div onClick={handleCloseForm} className="short__modal-overlay">
        <div onClick={(e) => e.stopPropagation()} className="short__modal-form-wrapper">
            <img onClick={handleCloseForm} src={shortFormCloseIcon} alt="натисніть, щоб закрити форму" className="short__form-close-icon" />
          <h2 className="short__modal-form-title text-title-4">
            Замовити Консультацію
          </h2>
          <form
            onSubmit={handleSubmit}
            action="submit"
            className="short__form-form"
          >
            <div className="form__inputs-wrapper">
              <ReactInputMask
                mask="+38 (999) 999-99-99"
                value={userPhone}
                onChange={handlePhoneNumberChange}
                placeholder="Номер телефону"
                onFocus={(e) => e.target.setSelectionRange(6, 6)}
                onBlur={(e) => {
                  if (e.target.value === "+38 ()") {
                    setUserPhone("");
                  }
                }}
                className={`short__form-input ${PhoneError ? "error" : ""} text-title-3`}
                type="phone"
              />
            <button
              onSubmit={handleSubmit}
              className="short__form-button text-title-3"
              type="submit"
              >
              Передзвоніть мені
            </button>
                </div>
          </form>
          <p className="short__form-description text-base-r">
          *Консультація та аудит надаються безкоштовно
          </p>
        </div>
      </div>
    </div>
  );
};

export default ShortModalForm;
